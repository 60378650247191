.security-container {
  width: 70vw;
  height: auto;
}
.security-container .security-header {
  width: 100%;
  height: 15vh;
  border-bottom: 2px solid #ebebeb;
}
.security-container .security-contents-container {
  width: 100%;
}
.security-container .security-contents-container .security-contents {
  width: 85%;
  border-left: 2px solid #ebebeb;
  border-right: 2px solid #ebebeb;
}
.security-container .security-contents-container .security-contents .security-email {
  width: 100%;
}
.security-container .security-contents-container .security-contents .security-email .security-email-content-1 {
  width: 80%;
}
.security-container .security-contents-container .security-contents .security-email .toggle-container {
  width: 20%;
  height: 20%;
  border-radius: 40%;
  background-color: #ebebeb;
}
.security-container .security-contents-container .security-contents .security-email .toggle-container .toggle-btn {
  width: 10%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
}

.text-input {
  width: 90%;
  outline: none;
  border: none;
  background-color: #ebebeb;
  padding: 1% 2% 1% 2%;
}

.bordered {
  border: 1px solid red;
}

.input-icons {
  margin-left: -10%;
  height: 100%;
}
.input-icons .input-eye-icon {
  margin-left: 4%;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .security-container {
    width: 70vw;
  }
  .security-container .security-header {
    height: 8vh;
  }
  .security-container .security-contents-container {
    width: 100%;
  }
  .security-container .security-contents-container .security-contents {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .security-container {
    width: 100vw;
    height: auto;
  }
  .security-container .security-header {
    width: 100%;
    height: 10vh;
  }
  .security-container .security-contents-container {
    width: 100%;
  }
  .security-container .security-contents-container .security-contents {
    width: 100%;
    border: none;
  }
}/*# sourceMappingURL=style.css.map */