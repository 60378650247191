.App {
  background-color: #0b0230;
  width: 100vw;
  /* padding-block: 2rem; */
}
 
@font-face {
  font-family: AgrandirBold;
  src: url(./Assets/fonts/Agrandir-Font/Agrandir-TextBold.otf);
}

@font-face {
  font-family: AgrandirGrandLight;
  src: url(./Assets/fonts/Agrandir-Font/Agrandir-GrandLight.otf);
}

@font-face {
  font-family: AgrandirWideLight;
  src: url(./Assets/fonts/Agrandir-Font/Agrandir-WideLight.otf);
}

@font-face {
  font-family: AgrandirHeavy;
  src: url(./Assets/fonts/Agrandir-Font/Agrandir-GrandHeavy.otf);
}

@font-face {
  font-family: AgrandirRegular;
  src: url(./Assets/fonts/Agrandir-Font/Agrandir-Regular.otf);
}

@font-face {
  font-family: AgrandirTight;
  src: url(./Assets/fonts/Agrandir-Font/Agrandir-Tight.otf);
}

@font-face {
  font-family: AgrandirBlackItalic;
  src: url(./Assets/fonts/Agrandir-Font/Agrandir-WideBlackItalic.otf);
}

@font-face {
  font-family: AgrandirTinItalic;
  src: url(./Assets/fonts/Agrandir-Font/Agrandir-ThinItalic.otf);
}

@font-face {
  font-family: AgrandirNarrow;
  src: url(./Assets/fonts/Agrandir-Font/Agrandir-Narrow.otf);
}

/*kumns sans font*/

@font-face {
  font-family: KB-sans-Bold;
  src: url(./Assets/fonts/kumbh-sans/KumbhSans-Bold.ttf);
}

@font-face {
  font-family: KB-sans-Regular;
  src: url(./Assets/fonts/kumbh-sans/KumbhSans-Regular.ttf);
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(./Assets/fonts/static/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Montserrat-Light;
  src: url(./Assets/fonts/static/Montserrat-Light.ttf);
}

@font-face {
  font-family: Montserrat-Black;
  src: url(./Assets/fonts/static/Montserrat-Black.ttf);
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(./Assets/fonts/static/Montserrat-Bold.ttf);
}

@font-face {
  font-family: Montserrat-LightItalic;
  src: url(./Assets/fonts/static/Montserrat-LightItalic.ttf);
}

@font-face {
  font-family: Inter-Regular;
  src: url(./Assets/fonts/inter/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(./Assets/fonts//inter/Inter-Bold.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(./Assets/fonts/inter/Inter-Bold.ttf);
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url(./Assets//fonts/inter/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: Inter-Black;
  src: url(./Assets/fonts/inter/Inter-Black.ttf);
}

@media screen and (min-width: 620px) {
  .flexy {
    display: flex;
  }
}

@media screen and (max-width: 620px) {
  .flexyM {
    display: flex;
  }
}