@import url("https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300;700&display=swap");
.notify {
  cursor: pointer;
}

.messages {
  width: auto;
  position: fixed;
  top: 6%;
  right: 6%;
  z-index: 4;
  background-color: #fff;
  border: 2px solid rgba(217, 217, 217, 0.54);
  box-shadow: 0px 4px 10px rgba(255, 251, 251, 0.25);
  border-radius: 10px;
  font-family: "Yanone Kaffeesatz", sans-serif;
  overflow: auto;
  height: 50%;
}
.messages .items {
  list-style: none;
  cursor: pointer;
}

.logout {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.logout .word {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -100px;
}
.logout:hover .word {
  visibility: visible;
} /*# sourceMappingURL=header.css.map */
.action-bar {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: fit-content;
  /* padding-top: 5px; */
  width: 100% !important;
}

.action-bar h6 {
  flex-grow: 1;
  text-align: left !important;
  color: #2a62f2;
  margin-left: 50px;
  font-size: 14px !important;
}
.py-3 {
  padding: 0 !important;
  border: none !important;
  margin-bottom: 0 !important;
  box-shadow: none !important;
  background: rgb(198, 213, 241);
  background: linear-gradient(
    90deg,
    rgba(198, 213, 241, 1) 4%,
    rgba(157, 196, 253, 1) 21%
  );
  height: 40px !important;
}
.px-4 {
  padding: 0 !important;
}
.nav-items {
  margin-top: -10px;
}
@media screen and (max-width: 620px) {
  .py-3 {
    height: fit-content !important;
    padding-bottom: 10px !important;
  }
  .nav-items {
    /* margin-top: -0px; */
  }
}
