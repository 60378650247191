.chart {
  padding: 1rem;
  border-radius: 12px;
  background-color: transparent;
  text-align: center;
  display: flex;
  justify-content: space-around;
  height: 10rem;
  width: 100%;
}
