.verify {
    padding: 5% 30%;
    /* width: 60vw !important; */
    width: 50%;
    margin: 15% auto;
    box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
}

.verify label{
    font-weight: 700;
}

.verify-input {
    width: 100% !important;
    outline: none !important;
    border: none !important;
    border-bottom: 2px solid #000 !important;
    border-radius: none !important;
}

@media screen and (max-width: 1023px) {
    .verify {
        width: 60%;
        margin: 40% auto;
    }
}

@media screen and (max-width: 767px) {
    .verify {
        width: 80%;
        margin: 50% auto;
    }

}