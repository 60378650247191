.sliderBody {
  background: #fff;
  /* height: 200px !important; */
  display: block !important;
}

.css-1hb7zxy-IndicatorsContainer {
  /* border-radius: 10px !important; */
  margin-top: 4px !important;
  border: solid 2px rgb(228, 224, 224) !important;
  border-left: none !important;
  height: 45px !important;
  margin-left: -15px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  /* margin-bottom: 50px !important; */
}
.css-qbdosj-Input {
  border: solid 2px rgb(228, 224, 224) !important;
  height: 45px !important;
  width: 105% !important;
  /* margin-bottom: 50px !important; */
  /* margin-top: 30px !important; */
  margin-left: -5px !important;
  border-right: none !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.sliderBody h6 {
  margin-top: 30px;
  color: grey;
  font-family: AgrandirRegular;
}
.slider-container {
  margin-top: 20px !important;
  padding: 0px 30px;

  font-family: AgrandirRegular;
}
.sliderContainer {
  background: #fff !important;
}
.closeBtn {
  display: none;
}

@media screen and (max-width: 620px) {
  .slider-container {
    left: 5% !important;
  }
}

.account-type-btn {
  height: 230px;
  border-radius: 30px;
  padding: 20px 130px;
  margin: 40px 0;
  border: none;
  font-family: AgrandirBold;
  font-size: 25px;
}
.account-type-btn img {
  /* background: yellow; */
  height: 20%;
  width: 15%;
  padding: 2% 2%;
}
.account-type-btn h6 {
  font-family: AgrandirBold;
  color: #082e88;
}
.account-type-btn p {
  line-height: 30px;
  color: #082e88;
  font-weight: bold;
}
.otp-slide {
  /* width: "550px" !important;
  left: "35%"; */
}
@media screen and (max-width: 620px) {
  .otp-slide {
    /* left: 5%; */
  }
  .account-type-btn {
    padding: 20px 60px;
  }
  .account-type-btn p {
    font-size: 20px;
    margin-top: 20px;
  }
}
/* .otp-slide {
  width: "550px" !important;
}
@media screen and (min-width: 620px) {
  .otp-slide {
  }
} */
