.transactions-tab .tabs {
  display: flex;
  /* border: solid 1px rgb(205, 200, 200); */
  margin-top: 30px;
  border-radius: 10px;
  font-family: AgrandirBold;

  padding-left: 10px;
  /* box-shadow: 0 0 3px 3px rgb(205, 200, 200); */
}
.eye {
  color: #fff;
}
.transactions-tab .tab {
  justify-content: center;
  align-items: center;
  width: 100%;

  border: none;
  color: #fff;
  background: transparent;
}

.transactions-tab .tab.active2 {
  border-bottom: solid 2px #476eef;
  color: #476eef;
  font-weight: bold;
}

.panel {
  display: none;
  /* padding: 20px; */
  /* color: #fff; */
}

@media screen and (max-width: 620px) {
  .transactions-tab .panel {
    padding: 0px;
  }
}

.transactions-tab .panel.active2 {
  display: block;
}

.new-invoice {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.hovered-btn:hover {
  opacity: 0.8 !important;
}
