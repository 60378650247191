.affilate-reg-container {
  width: 70vw;
  height: auto;
  font-family: Montserrat-Regular;
}
.affilate-reg-container .registeration-content {
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  width: 60%;
}
.affilate-reg-container .registeration-content .affilate-reg-header h1 {
  color: #2962f2;
}
.affilate-reg-container .registeration-content .affilate-reg-header p {
  color: #fd3003;
}
.affilate-reg-container .registeration-content .affilate-reg-inputs-container .inputs-container .input {
  width: 100%;
  padding: 1.5% 2%;
  outline: none;
}
.affilate-reg-container .registeration-content .affilate-reg-inputs-container .inputs-container .input:focus:invalid {
  border: 1px solid #fd3003;
}

.affilate-overview-container {
  width: 70vw;
  font-family: Inter-Regular;
}
.affilate-overview-container .overview-contents {
  width: 100%;
}
.affilate-overview-container .overview-contents .overview-header-section .overview-header-input .header-input-search .search-icon {
  margin-right: -8%;
  z-index: 3;
}
.affilate-overview-container .overview-contents .overview-table-section {
  overflow-x: auto;
}
.affilate-overview-container .overview-contents .overview-table-section .active-header {
  color: #5fce2f;
}
.affilate-overview-container .overview-contents .overview-table-section .table-1 .table-1-contents thead tr .pointer {
  cursor: pointer;
}
.affilate-overview-container .overview-contents .overview-table-section .table-2 .info {
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
  padding: 12% 0;
}
.affilate-overview-container .overview-contents .overview-table-section .table-2 .info td,
.affilate-overview-container .overview-contents .overview-table-section .table-2 .info th {
  border: none;
  padding: 8px;
}
.affilate-overview-container .overview-contents .overview-table-section .table-2 .info th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: #000;
  background-color: #f7f7f8;
  font-family: Inter-Bold;
}
.affilate-overview-container .overview-contents .buttons {
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .affilate-reg-container {
    width: 90vw;
  }
  .affilate-reg-container .registeration-content {
    width: 80%;
  }
  .affilate-overview-container {
    width: 90vw;
  }
  .affilate-overview-container .overview-contents {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .affilate-reg-container {
    width: 95vw;
  }
  .affilate-reg-container .registeration-content {
    width: auto;
  }
  .affilate-overview-container {
    width: 95vw;
  }
  .affilate-overview-container .overview-contents {
    width: 100%;
  }
}/*# sourceMappingURL=affilate.css.map */