.profile-container {
  width: 70vw;
  height: auto;
  .profile-header {
    width: 100%;
    height: 15%;
    border-bottom: 2px solid #ebebeb;
  }

  .profile-contents-container {
    width: 100%;
    .profile-contents {
      width: 85%;
      border-left: 2px solid #ebebeb;
      border-right: 2px solid #ebebeb;
    }

    .profile-edit {
      width: 60%;

      .profile-img {
        width: 20%;
        height: 20%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

#btn-coloured {
  background-color: #2962f2;
}

#btn-normal {
  background-color: #ebebeb;
}

.text-input {
  width: 90%;
  outline: none;
  border: none;
  background-color: #ebebeb;
  padding: 1% 2% 1% 2%;
}

#options {
  width: 100%;

  #text-input-width {
    width: 80%;
  }
  #text-input-width-1 {
    width: 20%;
  }
}

.select-btn {
  width: 90%;
  outline: none;
  padding: 1% 2% 1% 2%;
  background-color: #ebebeb;
}

.cursor {
  cursor: pointer;
}

.PhoneInputInput {
  border-radius: 0.2rem;
  outline: none;
  border: none;
  &:focus {
    outline: none;
    border: none;
  }
}

.react-select__control {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

@media screen and (min-width: 1024px) {
  .profile-container {
    width: 70vw;
    height: auto;
    .profile-header {
      width: 100%;
      height: 15vh;
    }

    .profile-contents-container {
      width: 100%;
      .profile-contents {
        width: 85%;
      }

      .profile-edit {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .profile-container {
    width: 70vw;

    .profile-header {
      height: 8vh;
    }
    .profile-contents-container {
      width: 100%;
      .profile-contents {
        width: 100%;
      }

      .profile-edit {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  * {
    font-size: 10px;
  }

  .profile-container {
    width: 100vw;
    height: auto;

    .profile-contents-container {
      width: 100%;
      .profile-contents {
        width: 100%;
        border: none;
      }

      .profile-edit {
        width: 100%;
      }
    }
  }
}
