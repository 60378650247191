.subscription-container {
  width: 100%;
  .card-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .input-section {
    font-family: AgrandirRegular;
    .input-container {
      .input {
        &:focus {
          border: none;
        }
      }
      .input-icon {
        margin-left: -90%;
      }
    }
  }

  .table-section {
    width: 100%;
    overflow-x: auto;
    font-family: AgrandirRegular;
    .table1 {
      width: 100%;
      .table-border1 {
        border-bottom: 4px solid #282541;
        .headers {
          font-family: AgrandirRegular;
          &:hover {
            cursor: pointer;
          }
        }
        .active-tab {
          border-bottom: 4px solid #082e88;
        }
      }
    }
    .table2 {
      width: 100%;
      overflow-x: auto;

      .subscription-details-container,
      .success-msg-1 {
        font-family: Montserrat-Regular;
        width: 40%;
        background-color: white;
        position: fixed;
        top: 0%;
        left: 40%;
        z-index: 40;
        max-height: 100vh;
        box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
        .top-section {
          height: 20%;
          display: flex;
          flex-direction: row !important;
          justify-content: space-between !important;
          align-items: center;
        }
        .header {
          color: #fc0019;
        }
        .close-btn {
          cursor: pointer;
        }
      }
      .confirmation {
        font-family: Montserrat-Regular;
        width: 25%;
        background-color: white;
        position: fixed;
        top: 30%;
        left: 45%;
        z-index: 60;
        box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
      }

      .success-msg-1 {
        top: 2%;
        z-index: 61;
        color: #082e88;
        overflow-y: auto;
        .header-section {
          background-color: #082e88;
          .header-text {
            color: #ffffff;
          }
        }
        .image-section {
          .red-hr {
            border-top: #fc0019;
            color: #fc0019;
          }
        }
      }
      .subscription-details-container{
        overflow-y: auto;
      }
    }
    .noValue{
      margin: 5% auto;
      font-weight: bold;
    }
  }
}

.tbody {
  .table-link {
    color: #082e88;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}

.orange {
  color: #f5a31d;
}

.blue {
  color: #082e88;
}

.green {
  color: #5fce2f;
}

.red {
  color: #fd3003;
}

.black {
  color: #000000;
}

@media screen and (min-width: 1024px) {
  .subscription-container {
    .card-section {
      display: flex;
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 1023px) {
  .subscription-container {
    // padding-left: 8%;
    .card-section {
      flex-wrap: wrap;
    }

    .table2 {
      .subscription-details-container,
      .confirmation {
        width: 65% !important;
        left: 20% !important;
        top: 30% !important;
      }

      .confirmation {
        top: 50% !important;
      }

      .success-msg-1 {
        width: 80% !important;
        left: 15% !important;
        top: 30% !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .subscription-container {
    .card-section {
      flex-wrap: wrap !important;
    }

    .table2 {
      .subscription-details-container,
      .confirmation {
        width: 80% !important;
        left: 10% !important;
        top: 30% !important;
      }

      .confirmation {
        top: 40% !important;
      }

      .success-msg-1 {
        width: 80% !important;
        left: 10% !important;
        top: 30% !important;
      }
    }
  }
}
