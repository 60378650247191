.chart-container .canvasjs-chart-canvas {
  width: 250px !important;
  height: 200px !important;
  position: relative !important;
  z-index: 1 !important;
}
.canvasjs-chart-toolbar button {
  background: transparent !important;
}
.canvasjs-chart-credit {
  display: none !important;
}
.canvasjs-chart-container {
  /* border-radius: 20px !important; */
}
