.css-13cymwt-control {
  /* color: black !important; */
  font-family: AgrandirBold !important;
  /* color: red !important; */
  border: none !important;
}
.css-qbdosj-Input {
  /* background-color: rgb(216, 212, 212); */
  border: none !important;
  color: black !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 20px !important;
  padding: 0 !important;
}
.css-b62m3t-container {
}
