.cards {
  width: 20%;
  margin-right: 2%;
  margin-top: 4%;
  font-family: AgrandirRegular;
}

@media screen and (min-width: 1024px) {
  .cards {
    width: 20%;
    margin-right: 2%;
  }
}
@media screen and (max-width: 1023px) {
  .cards {
    width: 45%;
  }
}
@media screen and (max-width: 767px) {
  .cards {
    width: 45%;
    margin-top: 2%;
  }
}/*# sourceMappingURL=style.css.map */