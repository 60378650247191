/** Merchant Dashboard **/
#merchant-dashboard .top-nav {
  /** Top-Nav **/
  margin-left: 0;
}

@media (min-width: 992px) {
  #merchant-dashboard .top-nav {
    /* margin-left: 24.999%; */
    padding-left: 17%;
    background: transparent;
    /* height: 20px; */
  }
}

#merchant-dashboard .sidebar-nav {
  /** Sidebar **/
  top: 0;
  width: 0;
  bottom: 0;
  /* display: none; */
  position: fixed;
  z-index: 100000;
  background: linear-gradient(to bottom, rgb(74, 118, 251), rgb(21, 5, 81));
}

#merchant-dashboard .sidebar-nav .contents {
  overflow-y: auto;
}

#merchant-dashboard .sidebar-nav .contents a {
  padding: 0.5rem 0.8rem 0.5rem 0.5rem;
  text-decoration: none;
  color: #fff;
}

#merchant-dashboard .sidebar-nav .contents a:first-of-type {
  padding-top: 0.5rem;
}

#merchant-dashboard .sidebar-nav .contents a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

#merchant-dashboard .sidebar-nav .contents a span {
  padding: 0.5rem;
}

#merchant-dashboard .sidebar-nav .contents a .link-icon:first-of-type {
  width: 35px;
}

@media screen and (max-width: 620px) {
  #merchant-dashboard .sidebar-nav .contents a .link-icon:first-of-type {
    width: 65px;
  }
}

#merchant-dashboard .sidebar-nav .contents .sidebar-links-bottom>a {
  width: 95%;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-bottom: 1rem;
  background-color: rgba(21, 5, 71, 0.5);
}

#merchant-dashboard .sidebar-nav .contents .sidebar-links-bottom>a:hover {
  background-color: rgba(21, 5, 71, 0.8);
}

#merchant-dashboard .sidebar-nav {
  width: 18%;
  display: flex;
  flex-direction: column;
  font-family: AgrandirRegular;
}

@media screen and (max-width: 620px) {
  .sidebar-nav {
    width: 70% !important;
    position: relative;
    z-index: 10;
    font-family: AgrandirRegular;
  }
}

@media screen and (min-width: 620px) {
  .mobile-controls {
    display: none;
  }
}

#merchant-dashboard .contents-container {
  /** Main Contents **/
  margin-left: 0;
  padding: 2rem 1rem;
}

@media (min-width: 992px) {
  #merchant-dashboard .contents-container {
    margin-left: 20.999%;
  }
}

/* @media screen and (min-width: 620px) {
  .active {
    width: 250px;
  }
} */

.slide-right {
  animation: 1s slide-right !important;
}

@keyframes slide-right {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0%;
  }
}

.slide-left {
  animation: 2s slide-left;
}

@keyframes slide-left {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

@media screen and (max-width: 620px) {

  .sidebar-nav span,
  .logout-btn {
    font-size: 20px;
  }
}

.sidebar-links-top {
  margin-top: 70px !important;
  font-family: AgrandirRegular;
}

.sidebar-links-top .mapped-items .custom-active {
  font-weight: bold;
  border: solid 1px #fff;
  background: #e4b42fb0;
  color: #2c379b !important;
  border-radius: 35px;
  width: 100%;
}

@media screen and (max-width: 620px) {
  .link-light span {
    font-size: 15px !important;
  }

  .sub-links .sub-link-items-container {
    width: 200px;
  }

  .sub-link-items-container>a {
    font-size: 2rem;
  }
}

.open {
  margin-left: -240px;
  margin-top: -65px;
}

/* .sub-links{
  margin-left: 14px;
} */

.sub-links {
  top: -20%;
  left: 50%;
  transform: translate(-25%, 25%);
  background-color: #000000d2;
  z-index: 5;
}

.sub-link-items-container {
  background: #2c379b;
  margin-left: -50% !important;
  border-radius: 1rem;
  width: 100%;
  font-size: 1.5rem !important;
}

.sub-links a {
  color: #fff !important;
}

.sub-links a:hover {
  color: #fff;
  background-color: #e4b42fb0;
  border-radius: 1em;
}