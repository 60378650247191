.payment-method {
  width: 100%;
}
.payment-method .form-container {
  width: 100%;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  justify-content: center;
}
.payment-method .form-container .remitance-btn-container {
  display: flex;
}
.payment-method .form-container .remitance-btn-container .remitance-btn {
  background-color: #0b0230;
  border-radius: 1rem;
  width: 20%;
  background-color: #0b0230;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2%;
}
.payment-method .form-container .form {
  width: 100%;
}
.payment-method .form-container .form .backgroundImg {
  background: url(../../Assets/payment-img.png) no-repeat center;
}
.payment-method .form-container .form .paymentimg {
  width: 100%;
  min-height: 80vh;
  font-family: AgrandirTinItalic;
  font-size: 1rem;
}
.payment-method .form-container .form .paymentimg .no-data {
  margin: 5% auto;
  height: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-method .form-container .form .paymentimg .table-container {
  height: 90%;
  width: 100%;
  font-family: AgrandirRegular;
  overflow-x: auto;
}
.payment-method .form-container .form .paymentimg .table-container .table-content {
  width: 100%;
  height: 40%;
}
.payment-method .form-container .form .paymentimg .table-container .table-content table {
  width: 100%;
}
.payment-method .form-container .form .paymentimg .table-container .table-content table .t-row .t-head1 {
  width: 100%;
  cursor: pointer;
}
.payment-method .activeHeader {
  border-bottom: 2px solid #008cff;
}
.payment-method .heading-text {
  font-family: KB-sans-Bold;
}
.payment-method .heading-text .page-text {
  color: rgba(244, 9, 9, 0.9215686275);
}
.payment-method .remitance-form {
  width: 100%;
  font-family: KB-sans-Bold;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
}
.payment-method .remitance-form .items-container {
  width: 50%;
}
.payment-method .remitance-form .items-container .success {
  width: 30%;
  height: 50%;
  background-color: #fff;
  top: 20%;
  right: 25%;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
}
.payment-method .remitance-form .items-container .success .success-items-container {
  height: 90%;
}
.payment-method .remitance-form .items-container .success .success-items-container .success-icon {
  height: 80%;
}
.payment-method .remitance-form .items-container .success .footer-section .items-container {
  width: 100%;
}
.payment-method .remitance-form .items-container .success .footer-section .items-container .first-content {
  width: 60%;
}
.payment-method .remitance-form .items-container .success .footer-section .items-container .second-content {
  width: 40%;
}

.inputs-container {
  width: 100%;
}
.inputs-container .banks,
.inputs-container .payment-interval {
  width: 100%;
  padding: 5% 8%;
  border-radius: 0.8rem;
  border: 2px solid #c1bdbd;
  font-size: 1rem;
  background-color: transparent;
}
.inputs-container .banks:focus,
.inputs-container .payment-interval:focus {
  border: none;
  outline: 2px solid #c1bdbd;
}
.inputs-container .banks .select-value,
.inputs-container .payment-interval .select-value {
  font-size: 1.2rem;
  padding: 4% 4%;
  background-color: transparent;
}
.inputs-container .banks .select-value:checked,
.inputs-container .payment-interval .select-value:checked {
  background-color: #1b0088;
  color: #fff;
}
.inputs-container .banks .select-value:hover,
.inputs-container .payment-interval .select-value:hover {
  background-color: #1b0088 !important;
}
.inputs-container .selected-bank-logo {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.inputs-container .selected-bank-logo .selected-logo {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}
.inputs-container .selected-bank-logo span {
  flex: 1;
}
.inputs-container .custom-option {
  display: flex;
  align-items: center;
  padding: 8px;
}
.inputs-container .custom-option:hover {
  background-color: #1b0088;
  color: #c1bdbd;
  cursor: pointer;
}
.inputs-container .custom-option.selected {
  background-color: #1b0088;
  color: #c1bdbd;
}
.inputs-container .custom-option.focused {
  background-color: lightblue;
  color: black;
}
.inputs-container .custom-option .option-logo {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}
.inputs-container .custom-option span {
  flex: 1;
}
.inputs-container .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
  display: -webkit-none !important;
}
.inputs-container .inputs-container-2 {
  width: 100%;
}
.inputs-container .inputs-container-2 .input {
  width: 100%;
  padding: 2rem 4rem;
  border-radius: 0.8rem;
  border: 2px solid #c1bdbd;
}
.inputs-container .inputs-container-2 .input:focus {
  border: none;
  outline: 2px solid #c1bdbd;
}
.inputs-container .inputs-container-2 .value-copy {
  margin-left: -8%;
  cursor: pointer;
}

.form-btn {
  margin-top: 10%;
}

.select-options {
  width: 25%;
  margin-top: 2%;
}
.select-options select {
  width: 100%;
  padding: 10%;
  border-radius: 0.4rem;
}
.select-options select:focus {
  outline: none;
}

.input-section .change-input-state {
  color: #1b0088;
  cursor: pointer;
}

.checkbox:checked {
  accent-color: #fff;
}

@media screen and (max-width: 1023px) {
  .payment-method {
    width: 70%;
  }
  .payment-method .form-container {
    width: 100%;
  }
  .payment-method .form-container .remitance-btn-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .payment-method .form-container .remitance-btn-container .remitance-btn {
    width: 40%;
    margin-top: 2%;
  }
  .payment-method .remitance-form {
    width: 100%;
    margin-top: 5%;
  }
  .payment-method .remitance-form .items-container {
    width: 60%;
  }
  .payment-method .remitance-form .items-container .success {
    width: 90%;
    left: 10%;
  }
  .inputs-container .inputs-container-2 .input {
    padding: 2rem 4rem;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .payment-method {
    width: 100%;
  }
  .payment-method .form-container {
    width: 100%;
    box-shadow: none;
  }
  .payment-method .form-container .form {
    width: 100%;
  }
  .payment-method .form-container .form .paymentimg {
    width: 100%;
    min-height: 60vh;
  }
  .payment-method .form-container .form .paymentimg .table-container {
    margin: 2% 0 30% 0;
  }
  .payment-method .form-container .remitance-btn-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .payment-method .form-container .remitance-btn-container .remitance-btn {
    width: 40%;
    margin-top: 2%;
  }
  .payment-method .remitance-form {
    width: 100%;
    margin-top: 5%;
  }
  .payment-method .remitance-form .items-container {
    width: 80%;
  }
  .payment-method .remitance-form .items-container .success {
    left: 10%;
    width: 80%;
  }
  .inputs-container .inputs-container-2 .input {
    padding: 2.5rem 4rem;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }
}/*# sourceMappingURL=style.css.map */