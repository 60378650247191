@import url("https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300;700&display=swap");

.notify {
  cursor: pointer;
}

.messages {
  width: auto;
  position: fixed;
  top: 6%;
  right: 6%;
  z-index: 4;
  background-color: #fff;
  border: 2px solid rgba(217, 217, 217, 0.54);
  box-shadow: 0px 4px 10px rgba(255, 251, 251, 0.25);
  border-radius: 10px;
  font-family: "Yanone Kaffeesatz", sans-serif;
  overflow: auto;
  height: 50%;
  .items {
    list-style: none;
    cursor: pointer;
  }
}

.logout {
  cursor: pointer;
  position: relative;
  display: inline-block;

  .word {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -100px;
  }

  &:hover {
    .word {
      visibility: visible;
    }
  }
}
