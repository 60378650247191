.affilate-reg-container {
  width: 70vw;
  height: auto;
  font-family: Montserrat-Regular;

  .registeration-content {
    box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
    width: 60%;
    .affilate-reg-header {
      h1 {
        color: #2962f2;
      }
      p {
        color: #fd3003;
      }
    }

    .affilate-reg-inputs-container {
      .inputs-container {
        .input {
          width: 100%;
          padding: 1.5% 2%;
          outline: none;
          &:focus:invalid {
            border: 1px solid #fd3003;
          }
        }
      }
    }
  }
}

.affilate-overview-container {
  width: 70vw;
  font-family: Inter-Regular;

  .overview-contents {
    width: 100%;
    .overview-header-section {
      .overview-header-input {
        .header-input-search {
          .search-icon {
            margin-right: -8%;
            z-index: 3;
          }
        }
      }
    }

    .overview-table-section {
      overflow-x: auto;
      .active-header {
        color: #5fce2f;
      }
      .table-1 {
        .table-1-contents {
          thead {
            tr {
              .pointer {
                cursor: pointer;
              }
            }
          }
        }
      }

      .table-2 {
        .info {
          border-collapse: collapse;
          width: 100%;
          overflow-x: auto;
          padding: 12% 0;

          td,
          th {
            border: none;
            padding: 8px;
          }

          th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            color: #000;
            background-color: #f7f7f8;
            font-family: Inter-Bold;
          }
        }
      }
    }

    .buttons {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1023px) {
  .affilate-reg-container {
    width: 90vw;
    .registeration-content {
      width: 80%;
    }
  }

  .affilate-overview-container {
    width: 90vw;

    .overview-contents {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .affilate-reg-container {
    width: 95vw;
    .registeration-content {
      width: auto;
    }
  }

  .affilate-overview-container {
    width: 95vw;

    .overview-contents {
      width: 100%;
    }
  }
}
