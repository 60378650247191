.welcome-main-container{
   
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 9999;
 
}


 
 
.welcome-signin-right-side{
  background: url("../../Assets/full-shot-woman-online-shopping.jpg");
  background-size:cover;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
 align-items: center;
}
.welcome-signin-left-side{
 
 width: 50%;
 height: 100%;
 display: flex;
  flex-direction: column;
 align-items: center;

 /* background-color: #000; */
}

.welcome-signin-left-side-top{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.welcome-signin-left-side-top-header{
  color: #0051B2;
  text-align: center;
  font-size: 22.688px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px; /* 148.099% */

}
.welcome-signin-left-side-top-text{
  color: var(--inbox-simpu-co-cape-cod, #323434);
  text-align: center;
  font-size: 17.297px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 185.005% */

}
.welcome-signin-left-side-bottom{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.welcome-signin-main-card{
 
  height: calc(100vh - 220px);
  width: 80%;
  overflow: auto;
  display: flex;
   flex-direction: column;
   align-items: flex-start;
 
   background: rgba(156, 201, 216, 0.17);
 
padding: 30px;


/* background: radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%); */
}

.welcome-help-web{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-top: 10px;
  cursor: pointer;
}

 
.welcome-secure-box{
  display: flex;
   justify-content: flex-end;
   align-items: center;
   gap:38%;
   margin-top: 15px;
}
 
 
.welcome-login-form-title{
  color: #0051B2;
text-align:start;
font-size: 22.688px;
font-style: normal;
font-weight: 700;
 
}

.welcome-login-form-input{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

}

.welcome-username-input{
  display: flex;
  padding: 9.66px 16.667px 9.67px 16.667px;
 justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
      width: 100%;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid #0051B2;
      background: #FFF;
      box-shadow: 0px 1px 2px 0px rgba(2, 43, 105, 0.03);
}
.welcome-userInput{
  width: 100%;
  outline: none;
  background: transparent;
  border: transparent;
  color:   #959898;
  font-size: 15.75px;
  font-style: normal;
  font-weight: 400;
   
  

}
.welcome-userInput::placeholder{
  color:  #959898;
  background: transparent;
}

.welcome-forgot-password-div{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.welcome-remember-input-span{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  
}
.welcome-account-creation{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin-top: 10px;
}
.welcome-remember-text{
  color: #FFF;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 10px; /* 218.75% */
  text-decoration: none;
}
.welcome-form-text-one{
  color: #323434;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 153.6% */
  text-decoration: none;
 
}

.welcome-form-text-two{
  color: #323434;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 153.6% */
  text-decoration: none;
 
}
.welcome-login-button{
  display: flex;
  /* padding: 13.9px 187.53px 13.1px 187.1px; */
  width: 100%;
  height: 44px;
   
  justify-content: center;
  align-items: center;
  gap: -0.63px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--inbox-simpu-co-blue-ribbon, #026AE8);
  border: transparent;
  color: var(--inbox-simpu-co-nero, #FFF);
  text-align: center;
  font-size: 15.75px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px; /* 121.905% */
  margin-top: 20px;
  cursor: pointer;
}

.welcome-login-form-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
}
.welcome-color-one{
  color:   #026AE8;
  text-decoration: none;
}
.welcome-text
.welcome-form-text-three{
  color: #FFF;
 text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 250% */
 
}
.welcome-login-form-label{
  color: var(--inbox_simpu_co_get-started_1280x572_default-Cape-Cod, #323434);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-top: 10px;
}

.welcome-signin-poster-icon{
  height: 520px;
  margin-top: 70px;
  margin-left: 90px;
}

.welcome-signin-right-side-footer{

 
  display: flex;
 flex-direction: column;
   margin-top: calc(100vh - 250px);
   
  

}

.welcome-footer-down{
  width: 246px;
  height: 158px;
  border-radius: 14.317px;
  background: rgba(0, 0, 0, 0.14);
  backdrop-filter: blur(21.475366592407227px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 23.625px;
  font-style: normal;
  font-weight: 600;
  line-height: 31.92px; /* 135.111% */
}
.welcome-form-text-four{
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 172.727% */
}

.welcome-form-text-five{
  color: #FFF;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.welcome-footer-top{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.welcome-page-router{
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  width: 100%;
}
.welcome-single-router-inactive{
  width: 56px;
  height: 6px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #B6B6B6;
  cursor: pointer;
}

.welcome-single-router-active{
  width: 56px;
  height: 6px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #026AE8;
  cursor: pointer;
}

.welcome-agreement-div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
}


@media screen and (max-width:748px) {
  .welcome-main-container{
   
    min-height: 100vh;
    width: 100%;
    background-size:cover;
    display: flex;
    flex-direction:column ;
    padding-left: 0px;
    padding-right: 0px;
    padding: 5px
    ;
   
  }

  .welcome-signin-div {
    height: 100%;
    width: 100%;
    /* height: 100%; */
  display: flex;
 justify-content: center;
  gap: 10px;
   
  }
  .welcome-signin-right-side{
    display: none;
  }
  .welcome-signin-left-side{
   
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
  }

  .welcome-signin-main-card{
 
 
    width: 100%;
    overflow:visible;
 
  
  }
  .welcome-login-form-footer{
    display: flex;
     flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5px;
  }
}
  /*
  .secure-box{
    display: flex;
     justify-content:  center;
     flex-wrap: wrap;
     align-items: center;
     gap:10px;
     margin-top: 5px;
  }
  .help-web{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin-top: 0px;
    cursor: pointer;
  }

  .forgot-password-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
}





/* .formmy-container {
  height: fit-content;
  box-shadow: 0 0 5px 5px rgb(206, 206, 206); */
 
.welcome-form-heading {
  box-shadow: 0px 8px 5px 0px rgba(206, 206, 206, 0.75);
  -webkit-box-shadow: 0px 8px 5px 0px rgba(206, 206, 206, 0.75);
  -moz-box-shadow: 0px 8px 5px 0px rgba(206, 206, 206, 0.75);
  padding: 0 40px;
}

.welcome-form-heading h4 {
  color: #2d63f2;
  font-family: AgrandirBold;
  margin-top: 50px;
  text-align: left !important;
}

.welcome-sign-form-body input {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  /* margin-top: -10px; */
  background: #e6eaf6;
  border: none;
  outline: none;
  padding-left: 10px;
}

::placeholder {
  font-family: AgrandirRegular;
  color: #000;
  font-size: 12px;
}

.welcome-sign-form-body input:focus {
  outline: none;
  border: 2px solid #d4af37;
  transition: .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.welcome-sign-form-body {
  padding: 30px 25px 10px 25px;
}

.welcome-sign-form-body h6, .signin-label {
  margin-top: 35px;
  font-family: AgrandirBold;
  /* color: #fff; */
  text-align: left !important;
  display: block;
}

.welcome-sign-form-body button {
  width: 100%;
  margin-top: 60px;
  color: black;
  padding: 20px 0;
  border: none;
  border-radius: 10px;
  background: #2962f2;
  font-family: AgrandirBold;
  font-size: 18px;
}

.welcome-form-links {
  color: #2d63f2;
  text-decoration: none;
  font-family: AgrandirBold;
  font-size: 18px;
  text-align: left !important;
}

.welcome-form-links :hover {
  text-decoration: underline;
  color: #2d63f2 !important;
}

.welcome-form-links span {
  font-size: 20px;
  text-align: left !important;
}

.welcome-sign-form-body p {
  color: black;
  font-family: AgrandirBold !important;
  margin-top: 45px;
  margin-bottom: 0;

  text-align: left !important;
}

.welcome-sign-in:disabled {
  background: #abbce7;
}

.welcome-formmy-container img {
  margin-left: -35px;
  /* border-right: solid 1px rgb(226, 220, 220) !important; */
  border-radius: 7px;
}