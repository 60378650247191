.expenses-filter_controls {
  margin-bottom: 30px;
}
.expenses-filter_controls label {
  color: black;
  font-family: AgrandirBold;
  font-size: 8px;
  float: right;
  margin-right: 3px;
}
select {
  padding: 3px 6px;
  border-radius: 10px;
  font-size: 10px;
  float: right;
  margin-top: -4px;
}
