@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600");

.welcome-page-container {
  margin-top: 0px;
}
.welcome-paragraph h1,
h2 {
  color: #082e88;
  font-family: AgrandirBold;
}
.welcome-paragraph h1 {
  font-size: 70px;
}
.welcome-paragraph p {
  color: #2962f2;
  font-size: 25px;
  margin-top: 20px;
  font-family: AgrandirRegular;
}
.welcome-paragraph {
  padding: 100px 20px 0px 20px;
}
.wallet-container {
  margin-top: -140px;
}

.welcome-account-type {
  margin: 30px 0;
  background: #d9d9d9;
  padding: 50px;
}
.welcome-account-type h1 {
  font-family: AgrandirBold;
  color: #082e88;
}
.leverpay-acc-type {
  height: 230px;
  border-radius: 30px;
  padding: 20px 50px;
  margin: 40px 0;
}
.acc-type-link {
  text-decoration: none;
  color: #082e88;
}
.leverpay-acc-type img {
  margin: 10px 0;
}
.leverpay-acc-type p {
  line-height: 25px;

  font-family: AgrandirRegular;
}
.leverpay-acc-type h6 {
  font-family: AgrandirBold;
}

:root {
  --bar-scale-y: 0;
  --sparkle-color: rgb(253 244 215 / 40%);
}

@keyframes pop-word {
  to {
    transform: rotateX(0);
  }
}

@keyframes show {
  to {
    opacity: 1;
  }
}

@keyframes bar-scale {
  to {
    transform: scaleY(1);
  }
}

@keyframes sparkle {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1) translate(4px, 1px) rotate(8deg);
  }

  100% {
    transform: scale(0) translate(4px, 1px) rotate(8deg);
  }
}

@keyframes shimmer {
  to {
    text-shadow: 0 0 8px red;
  }
}

.welcome-heading {
  font-size: 8rem;
  line-height: 0.85;
  perspective: 500px;
}

.word {
  display: block;

  animation: show 0.01s forwards, pop-word 1.5s forwards;
  animation-timing-function: cubic-bezier(0.14, 1.23, 0.33, 1.16);
  opacity: 0;

  transform: rotateX(120deg);
  transform-origin: 50% 100%;
}

.word:nth-of-type(2) {
  padding: 0 2rem;

  animation-delay: 1.5s;

  color: gold;
}

.superscript {
  position: relative;
  animation-delay: 3.6s;

  animation-duration: 0.25s;
  animation-name: shimmer;

  vertical-align: text-top;
}

/* bars */
.superscript::before {
  /* --bar-width: 25%;

  position: absolute;

  top: 37%;
  left: 47%;
  width: 14%;
  height: 48%;

  animation: bar-scale 0.25s linear 3s 1 forwards;

  background: linear-gradient(
    to right,
    #f2a019 var(--bar-width),
    transparent var(--bar-width) calc(100% - var(--bar-width)),
    #f2a019 calc(100% - var(--bar-width))
  );

  content: "";

  transform: scaleY(var(--bar-scale-y)); */
}

/* sparkle */
.superscript::after {
  --size: 10rem;

  position: absolute;

  top: -5%;
  left: -85%;

  width: var(--size);
  height: var(--size);

  animation: sparkle 0.4s linear 3.5s 1 forwards;

  background: radial-gradient(
      circle at center,
      rgb(252 249 241 / 94%) 0% 7%,
      transparent 7% 100%
    ),
    conic-gradient(
      transparent 0deg 18deg,
      var(--sparkle-color) 18deg,
      transparent 20deg 40deg,
      var(--sparkle-color) 40deg,
      transparent 43deg 87deg,
      var(--sparkle-color) 87deg,
      transparent 95deg 175deg,
      var(--sparkle-color) 175deg,
      transparent 178deg 220deg,
      var(--sparkle-color) 220deg,
      transparent 222deg 270deg,
      var(--sparkle-color) 270deg,
      transparent 275deg 300deg,
      var(--sparkle-color) 300deg,
      transparent 303deg 360deg
    );

  border-radius: 50%;
  clip-path: polygon(
    50% 0,
    59.13% 26.64%,
    85.13% -2.35%,
    100% 50%,
    50% 100%,
    0 50%,
    31.39% 34.86%
  );

  content: "";

  filter: blur(1px);

  transform: scale(0);
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 5rem;
  }

  /* sparkle */
  .superscript::after {
    --size: 6rem;
  }
}

.coin-globe {
  /* margin-top: -30px; */
  animation: rotation 8s infinite linear;
  /* background: #fff !important; */
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.wel-page-imgs {
  /* height: 100%; */
  width: 100%;
}
@media screen and (max-width: 620px) {
  .wallet-container {
    margin-top: 10px;
  }
  .welcome-paragraph {
    text-align: center;
  }
  .welcome-account-type {
    padding: 50px 3px;
  }
}
