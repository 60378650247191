.profile-container {
  width: 100%;
  height: auto;
}
.profile-container .profile-header {
  width: 100%;
  height: 15%;
  border-bottom: 2px solid #ebebeb;
}
.profile-container .profile-contents-container {
  width: 100%;
}
.profile-container .profile-contents-container .profile-contents {
  width: 100%;
  border-left: 2px solid #ebebeb;
  border-right: 2px solid #ebebeb;
}

/* Profile upload style rule */
.profile-pic-wrapper {
  height: 100%;
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
}
.pic-holder {
  text-align: center;
  position: relative;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.pic-holder .pic {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.pic-holder .upload-file-block,
.pic-holder .upload-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(90, 92, 105, 0.7);
  color: #f8f9fc;
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.pic-holder .upload-file-block {
  cursor: pointer;
}

.pic-holder:hover .upload-file-block,
.uploadProfileInput:focus ~ .upload-file-block {
  opacity: 1;
}

.pic-holder.uploadInProgress .upload-file-block {
  display: none;
}

.pic-holder.uploadInProgress .upload-loader {
  opacity: 1;
}

select:disabled {
  opacity: 0 !important;
}

/* Snackbar css */
.snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 14px;
  transform: translateX(-50%);
}

.snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
/* End of profile upload style rule */

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}


.profile-container .profile-contents-container .profile-edit .profile-img img {
  width: 100%;
  height: 100%;
}

#btn-coloured {
  background-color: #2962f2;
}

#btn-normal {
  background-color: #ebebeb;
}

.text-input {
  width: 100% !important;
  outline: none;
  border: none;
  background-color: #ebebeb;
  padding: 1% 2% 1% 2%;
  font-family: AgrandirBold;
}
.profile-form label {
  font-family: AgrandirRegular;
  /* float: left !important; */
}
.profile-form {
  padding: 40px;
}
#options {
  width: 100%;
}
#options #text-input-width {
  width: 100%;
}
#options #text-input-width-1 {
  width: 20%;
}

.select-btn {
  width: 100%;
  outline: none;
  padding: 1% 2% 1% 2%;
  background-color: #ebebeb;
}

.cursor {
  cursor: pointer;
}

.PhoneInputInput {
  border-radius: 0.2rem;
  outline: none;
  border: none;
}
.PhoneInputInput:focus {
  outline: none;
  border: none;
}

.react-select__control {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

@media screen and (min-width: 1024px) {
  .profile-container {
    width: 70vw;
    height: auto;
  }
  .profile-container .profile-header {
    width: 100%;
    height: 15vh;
  }
  .profile-container .profile-contents-container {
    width: 100%;
  }
  .profile-container .profile-contents-container .profile-contents {
    width: 85%;
  }
  .profile-container .profile-contents-container .profile-edit {
    width: 50%;
  }
}
@media screen and (max-width: 1023px) {
  .profile-container {
    width: 70vw;
  }
  .profile-container .profile-header {
    height: 8vh;
  }
  .profile-container .profile-contents-container {
    width: 100%;
  }
  .profile-container .profile-contents-container .profile-contents {
    width: 100%;
  }
  .profile-container .profile-contents-container .profile-edit {
    width: 100%;
  }
  .profile-pic-wrapper{
    width: 40%;
  }
}
.profile-contents h2 {
  color: black;
  font-family: AgrandirBold;
  margin-left: 40px;
}
.profile-contents p {
  color: black;
  font-family: AgrandirBold;
  margin-left: 40px;
}
.profile-contents span {
  color: #2a62f2;
  font-size: 28px;
}
@media screen and (max-width: 767px) {
  * {
    font-size: 10px;
  }
  .profile-container {
    width: 100vw;
    height: auto;
  }
  .profile-container .profile-contents-container {
    width: 100%;
  }
  .profile-container .profile-contents-container .profile-contents {
    width: 100%;
    border: none;
  }
  .profile-container .profile-contents-container .profile-edit {
    width: 100%;
  }

  .profile-pic-wrapper{
    width: 30%;
  }
} /*# sourceMappingURL=style.css.map */
