.forget-password-div {
  background: linear-gradient(177.42deg, #0051ff -2.29%, #0b0230 113.02%);

  margin: 0 !important;
  padding: 250px 0;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forget-password-div h1 {
  font-family: AgrandirBold;
  margin-bottom: 40px;
  color: #fff;
}

.forget-password-div h6 {
  font-family: AgrandirBold;
  float: left;
  font-size: 12px;
  color: grey;
}

.forget-password-form {
  background: #fff;
  padding: 50px 30px;
  border-radius: 30px;
  margin-bottom: 40px;
  width: 50%;
}

.forget-password-form input {
  margin: 0px 0 30px 0;
  width: 100%;
  padding: 7px 10px;
  border: solid 1px rgb(175, 172, 172);
  background-color: rgb(253, 224, 228);
  border-radius: 8px;
  font-family: AgrandirBold;
}

.forget-password-form button {
  width: 100%;
  border: none;
  padding: 10px 10px;
  border-radius: 8px;
  background: #082e88;
  color: #fff;
  font-family: AgrandirBold;
}

.fplink {
  color: #fff;
  font-family: AgrandirBold;
  /* text-decoration: none; */
}

.eye-icon {
  float: right;
  margin-top: -60px;
  margin-right: 10px;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .forget-password-form {
    width: 65%;
  }
}

@media screen and (max-width: 1023px) {
  .forget-password-form {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .eye-icon {
    float: right;
    margin-top: -50px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 620px) {
  .forget-password-div {
    padding: 100px 10px 300px 10px;
  }
}
