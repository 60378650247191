.main-container{
   
  height: 100vh;
  width: 100vw;
  background: url("../../Assets/merchant\ backgroun-three.png");
  background-size:cover;
  display: flex;
  flex-direction:column ;
 
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
 
 
}


.signin-div {
  height: 100%;
  width: 100%;
  /* height: 100%; */
display: flex;
justify-content: space-evenly;
align-items: center;
gap: 10px;
z-index: 9999;

 
}
.signin-right-side{
  width: 45%;
  display: flex;
  flex-direction: column;
 align-items: center;
}
.signin-left-side{
 
 width: 50%;
 display: flex;
 justify-content: center;
 align-items: center;
}
.signin-main-card{
 
  height: 580px;
  width: 450px;
  display: flex;
   flex-direction: column;
   align-items: center;
 
  border-radius: 30px;
border: 2px solid #FFF;
background-color: rgb(141, 137, 137);
border-radius: 50px;
border: 1px solid rgba(255, 255, 255, 0.15);
background: rgba(255, 255, 255, 0.08);
backdrop-filter: blur(5.599999904632568px);
padding: 30px;


/* background: radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%); */
}

.help-web{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-top: 10px;
  cursor: pointer;
}

 
.secure-box{
  display: flex;
   justify-content: flex-end;
   align-items: center;
   gap:38%;
   margin-top: 15px;
}
 
 
.login-form-title{
  color: #FFF;
 text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 125% */
  margin-top: 30px;
}

.login-form-input{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;

}

.username-input{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #FFF;
  opacity: 1;
  background: #B6B6B6;
  width: 100%;
  gap: 12px;
 
  padding: 5px 10px 5px 10px;
  margin-bottom: 20px;

}
.userInput{
  width: 100%;
  outline: none;
  background: transparent;
  border: transparent;
  color: #fff;
  

}
.userInput::placeholder{
  color: #fff;
}

.forgot-password-div{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.remember-input-span{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  
}
.account-creation{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin-top: 10px;
}
.remember-text{
  color: #FFF;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 10px; /* 218.75% */
  text-decoration: none;
}
.form-text-one{
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
 
}

.form-text-two{
  color: #FFF;
 
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
 
}
.login-button{
  width: 100%;
  border-radius: 15px;
  background: #026AE8;
  height: 45px;
  outline: none;
  border: transparent;
  color: #FFF;
 
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 35px; /* 175% */
  margin-top: 25px;
}

.login-form-footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
}
.form-text-three{
  color: #FFF;
 text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 250% */
 
}
.login-form-label{
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px; /* 218.75% */
}

.signin-poster-icon{
  height: 520px;
  margin-top: 70px;
  margin-left: 90px;
}

.signin-right-side-footer{
  width: 100%;
  display: flex;
 flex-direction: column;
  align-items: center;
}
.form-text-four{
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 172.727% */
}

.form-text-five{
  color: #FFF;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.footer-top{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}



@media screen and (max-width:748px) {
  .main-container{
   
    min-height: 100vh;
    width: 100%;
    background-size:cover;
    display: flex;
    flex-direction:column ;
    padding-left: 0px;
    padding-right: 0px;
    padding: 5px
    ;
   
  }

  .signin-div {
    height: 100%;
    width: 100%;
    /* height: 100%; */
  display: flex;
 justify-content: center;
  gap: 10px;
   
  }
  .signin-right-side{
    display: none;
  }
  .signin-left-side{
   
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
  }

  .signin-main-card{
 
 
    width: 100%;
 
  
  }
  .login-form-footer{
    display: flex;
     flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5px;
  }
}
  /*
  .secure-box{
    display: flex;
     justify-content:  center;
     flex-wrap: wrap;
     align-items: center;
     gap:10px;
     margin-top: 5px;
  }
  .help-web{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin-top: 0px;
    cursor: pointer;
  }

  .forgot-password-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
}





/* .formmy-container {
  height: fit-content;
  box-shadow: 0 0 5px 5px rgb(206, 206, 206); */
 
.form-heading {
  box-shadow: 0px 8px 5px 0px rgba(206, 206, 206, 0.75);
  -webkit-box-shadow: 0px 8px 5px 0px rgba(206, 206, 206, 0.75);
  -moz-box-shadow: 0px 8px 5px 0px rgba(206, 206, 206, 0.75);
  padding: 0 40px;
}

.form-heading h4 {
  color: #2d63f2;
  font-family: AgrandirBold;
  margin-top: 50px;
  text-align: left !important;
}

.sign-form-body input {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  /* margin-top: -10px; */
  background: #e6eaf6;
  border: none;
  outline: none;
  padding-left: 10px;
}

::placeholder {
  font-family: AgrandirRegular;
  color: #000;
  font-size: 12px;
}

.sign-form-body input:focus {
  outline: none;
  border: 2px solid #d4af37;
  transition: .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.sign-form-body {
  padding: 30px 25px 10px 25px;
}

.sign-form-body h6, .signin-label {
  margin-top: 35px;
  font-family: AgrandirBold;
  /* color: #fff; */
  text-align: left !important;
  display: block;
}

.sign-form-body button {
  width: 100%;
  margin-top: 60px;
  color: black;
  padding: 20px 0;
  border: none;
  border-radius: 10px;
  background: #2962f2;
  font-family: AgrandirBold;
  font-size: 18px;
}

.form-links {
  color: #2d63f2;
  text-decoration: none;
  font-family: AgrandirBold;
  font-size: 18px;
  text-align: left !important;
}

.form-links :hover {
  text-decoration: underline;
  color: #2d63f2 !important;
}

.form-links span {
  font-size: 20px;
  text-align: left !important;
}

.sign-form-body p {
  color: black;
  font-family: AgrandirBold !important;
  margin-top: 45px;
  margin-bottom: 0;

  text-align: left !important;
}

.sign-in:disabled {
  background: #abbce7;
}

.formmy-container img {
  margin-left: -35px;
  /* border-right: solid 1px rgb(226, 220, 220) !important; */
  border-radius: 7px;
}